import React, { useEffect, useState } from 'react';
import { API } from 'aws-amplify';
import { Alert, Button, Spinner } from 'react-bootstrap';
import { getAuthToken } from '../../utils/AuthUtils';
import UploadModal from '../../CommonComponents/UploadModal/UploadModal';
import GenericGridComponent from '../../GenericGridComponent/GenericGridComponent';
import './styles.scss';

const ClientMetaDataTab = () => {
    const [showUploadClientMetaDataModal, setShowUploadClientMetaDataModal] = useState(false);
    const [clientMetaData, setClientMetaData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [showError, setShowError] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);

    const columns = [{
        "key": "companyName",
        "name": "Client Name",
        "sortable": true,
        "filterable": true,
        "headerCellClass": 'filter-cell'
    }, {
        "key": "nickName",
        "name": "Nick Name",
        "sortable": true,
        "filterable": true
    }, {
        "key": "clientOwner",
        "name": "Client Owner",
        "sortable": true,
        "filterable": true
    }, {
        "key": "startDate",
        "name": "Start Date",
        "sortable": true,
        "filterable": true
    }];

    const retrieveClientMetaData = async () => {
        try {
            setLoading(true)
            setError("");
            setShowError(false);

            const authToken = await getAuthToken();
            API.get('AnnualCompensations', '/client-meta-data', {
                headers: {
                    'Token': authToken
                }
            }).then((response) => {
                setClientMetaData(response);
                setLoading(false);
            }).catch(error => {
                console.log(error);
                setError("Error loading client metadata list");
                setShowError(true);
                setLoading(false);
            });

        } catch (error) {
            console.log(JSON.stringify(error));
            setError("Exception occurred loading client metadata list");
            setShowError(true);
            setLoading(false);
        }
    }

    useEffect(() => {
        let mounted = true;
        if (mounted) {
            setLoading(false);
            setShowError(false);
            setError("");
            setShowUploadClientMetaDataModal(false);
            setClientMetaData([]);
            setCurrentPage(1);
            retrieveClientMetaData();
        }
    }, [])

    return (
        <div className="client-metadata-tab">
            <div className="upload-client-metadata">
                <Button variant="btn btn-primary btn-upload toolbar-btn" onClick={() => setShowUploadClientMetaDataModal(true)}>Upload<i className="fa fa-upload"></i>
                </Button>
                <Button variant="btn btn-primary btn-refresh toolbar-btn" onClick={() => retrieveClientMetaData()}>
                    <i className="fa fa-redo"></i>
                </Button>
                <UploadModal dataType={"Active Client"} show={showUploadClientMetaDataModal} onClose={() => setShowUploadClientMetaDataModal(false)} />
            </div>
            {loading ? <Spinner animation="border" variant="primary" className="spinner-blue" /> : <div className="client-metadata-grid">
                {showError && <Alert variant="danger" dismissible>{error}</Alert>}
                {clientMetaData.length > 0 ? <GenericGridComponent
                    columns={columns}
                    data={clientMetaData}
                    enableGridToCSV={true}
                    useCustomExportMethod={false}
                    fileName={"Client_MetaData"}
                    itemsName={"Clients"}
                    selectedCurrentPage={currentPage}
                    onCurrentPageSelect={(page) => setCurrentPage(page)}
                />
                    : <Alert style={{ marginTop: "1rem" }} variant="info">No Clients Found</Alert>}
            </div>}
        </div>
    )
}

export default ClientMetaDataTab;