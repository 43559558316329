import { API } from "aws-amplify";
import React, { useCallback, useEffect, useState } from 'react';
import { Alert, Form } from "react-bootstrap";
import { getAuthToken } from "../../../utils/AuthUtils";
import SearchResultDisplay from '../SearchResultDisplayComponent/SearchResultDisplay';

const ClientNameInputComponent = ({ clientName, setClientName, clientNameInvalid, setClientNameInvalid, handleSetClientOwner }) => {
    const clientNameFormGroupRef = React.createRef();
    const [clientNameInputFocused, setClientNameInputFocused] = useState(false);
    const [hasCompanyError, setHasCompanyError] = useState(false);
    const [loadingCompanies, setLoadingCompanies] = useState(false);
    const [companySearchResults, setCompanySearchResults] = useState([]);
    const [showCompanySearchResults, setShowCompanySearchResults] = useState(false);
    const [selectedCompanyResultIndex, setSelectedCompanyResultIndex] = useState(0);

    const debounce = (func, delay) => {
        let inDebounce
        return function () {
            const context = this
            const args = arguments
            clearTimeout(inDebounce)
            inDebounce = setTimeout(() => func.apply(context, args), delay)
        }
    }

    const lookupCompanies = async (searchPhrase) => {
        setLoadingCompanies(true);
        setHasCompanyError(false);
        searchPhrase = searchPhrase.toLowerCase();
        const queryStringParameters = { searchPhrase }
        const authToken = await getAuthToken();
        API.get("AnnualCompensations", "/client-meta-data/lookup", {
            headers: {
                'Token': authToken,
            },
            queryStringParameters
        })
            .then((response) => {
                setCompanySearchResults(response);
                setLoadingCompanies(false);
                setShowCompanySearchResults(true);
                setHasCompanyError(false)
            })
            .catch((error) => {
                console.log(error);
                setHasCompanyError(true);
                setLoadingCompanies(false);
                setShowCompanySearchResults(true);
            });
    }

    const handleCompanySelection = (actionType, selectedIndex) => {
        if (companySearchResults.length > 0) {
            const selectedCompanyRecord = actionType === "click" ? companySearchResults[selectedIndex] : companySearchResults[selectedCompanyResultIndex];
            if (selectedCompanyRecord === undefined) {
                setClientNameInvalid(true);
                setClientName("");
                return;
            }
            setClientName(selectedCompanyRecord.companyName);
            handleSetClientOwner(selectedCompanyRecord.clientOwner, selectedCompanyRecord.clientOwnerId, selectedCompanyRecord.clientOwnerEmail);
            setShowCompanySearchResults(false);
        }
    }

    const handleCompanySearch = (e) => {
        const company = e.target.value;
        setClientName(company);
        if (company.length > 0) {
            debounceChangeCompanyHandler(company)
        }
    }

    // eslint-disable-next-line
    const debounceChangeCompanyHandler = useCallback(debounce(lookupCompanies, 250), []);

    const handleClose = () => {
        setCompanySearchResults([]);
        setShowCompanySearchResults(false);
        setClientName("");
        setLoadingCompanies(false);
    }

    useEffect(() => {
        const formGroupElement = clientNameFormGroupRef.current;
        const handleFocusOut = (event) => {
            if (clientNameFormGroupRef.current && !clientNameFormGroupRef.current.contains(event.relatedTarget)) {
                setClientNameInputFocused(false);
            }
        };

        if (formGroupElement) {
            formGroupElement.addEventListener('focusout', handleFocusOut);
        }

        return () => {
            if (formGroupElement) {
                formGroupElement.removeEventListener('focusout', handleFocusOut);
            }
        };
    }, [clientNameFormGroupRef]);

    return (
        <Form.Group className="mb-2" controlId="clientNameforDataExportForm.clientName" ref={clientNameFormGroupRef}>
            <Form.Label>Client Name</Form.Label><Form.Control
                type="text"
                placeholder="Please enter name of the client to bill"
                onFocus={() => setClientNameInputFocused(true)}
                onSubmit={handleClose}
                value={clientName}
                onChange={(event) => handleCompanySearch(event)}
                onKeyDown={(e) => {
                    if (e.key === "Enter") {
                        e.preventDefault();
                        if (companySearchResults.length > 0) {
                            const selectedCompany = companySearchResults[selectedCompanyResultIndex];
                            if (selectedCompany !== undefined) {
                                setClientName(selectedCompany.companyName);
                                handleSetClientOwner(selectedCompany.clientOwner, selectedCompany.clientOwnerId, selectedCompany.clientOwnerEmail);
                            } else {
                                setClientNameInvalid(true);
                                setClientName("");
                            }
                            setShowCompanySearchResults(false);
                        }
                    }
                }}
                isInvalid={clientNameInvalid} /><Form.Control.Feedback type="invalid">
                Please enter client name to bill <b>or</b> mark as Non-billablel
            </Form.Control.Feedback>
            {clientNameInputFocused && <SearchResultDisplay
                showSearchResults={showCompanySearchResults}
                searchTerm={clientName}
                searchResults={companySearchResults}
                resultItem={"companyName"}
                handleUpdatingSelectedIndex={(index) => {
                    setSelectedCompanyResultIndex(index);
                }}
                loadingResults={loadingCompanies}
                handleSelection={(actionType, selectedIndex) => handleCompanySelection(actionType, selectedIndex)}
                selectedResultIndex={selectedCompanyResultIndex}
                setSelectedResultIndex={setSelectedCompanyResultIndex}
            />}
            {hasCompanyError ? <Alert variant="danger" className="user-lookup-error-alert" dismissible>
                There was an error retrieving the company information. Please try again later.
            </Alert> : null}
        </Form.Group>
    );
}

export default ClientNameInputComponent;