import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, NavDropdown, Spinner, Nav } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';

import GroupVisibleComponent from '../../GroupVisibleComponent';
import { ReactComponent as Logo } from '../../images/FWC-Logo.svg';

import { useStateStore } from '../../AppState';
import './styles.scss';
import { API } from 'aws-amplify';
import { getAuthToken } from '../../utils/AuthUtils';

const NavBar = ({ signOut, user, landingPagePath = "/" }) => {
	const { loading, setLoadingStatus } = useStateStore();
	const { pathname } = useLocation();

	const downloadLinkRef = useRef(null);

	const navigate = useNavigate();

	const getRelevantDoc = async (docType) => {
		setLoadingStatus(true);
		const url = '/relevant-documents?docType=' + docType;
		const authToken = await getAuthToken();
		API.get('AnnualCompensations', url, {
			headers: {
				'Token': authToken
			}
		}).then((response) => {
			setLoadingStatus(false);
			if (downloadLinkRef.current) {
				downloadLinkRef.current.href = response.url;
				downloadLinkRef.current.click();
			}
		}).catch((error) => {
			setLoadingStatus(false);
			if (error !== undefined && error.response !== undefined &&
				error.response.data !== undefined && error.response.data.message !== undefined) {
				console.log(error.response.data.message);
			} else {
				console.log("An error occurred while retrieving compensation data. Please try again later.");
			}
		});
	}

	return (
		<>
			{loading === true &&
				<div className='backdrop'>
					<div className='text-center'>
						<Spinner as="div" animation="border" size="lg" aria-hidden="true" role="status" />
					</div>
				</div>
			}
			<div className='navbar-container fixed-top'>
				<nav className="navbar navbar-expand-lg navbar-expand-sm">
					<div className="logo navbar-brand" style={{ "bottom": "0.8rem", "transform": "translateY(0.2rem)" }}>
						<Logo title="FW Cook" style={{ "cursor": "pointer", "width": "11.5rem" }} onClick={() => navigate(landingPagePath)} />
					</div>
					{user && <div className="collapse navbar-collapse" id="navbarSupportedContent" style={{ "justifyContent": "space-between", "alignItems": "flex-end" }}>
							<Nav className='main-navbar-actions' activeKey="/" onSelect={(selectedKey) => navigate(selectedKey)}>
								<NavDropdown title="Relevant Documents" active={pathname.includes("/relevant-documents/")}>
									<Dropdown.Item key="research-methodology" onClick={() => getRelevantDoc("methodology")}>
										Research Methodology
									</Dropdown.Item>
									<a href="#self" ref={downloadLinkRef} download id="download-relevant-doc" hidden={true}>Download</a>
								</NavDropdown>
								<GroupVisibleComponent groups={['FWC_Admin']}>
									<NavDropdown title="Admin" active={pathname.includes("/admin/")}>
										<Dropdown.Item>
											<Nav.Link eventKey="/admin/monthly-usage-report">Monthly Usage Report</Nav.Link>
										</Dropdown.Item>
										<Dropdown.Item>
											<Nav.Link eventKey="/admin/templates">Template Definitions</Nav.Link>
										</Dropdown.Item>
										<Dropdown.Item>
											<Nav.Link eventKey="/admin/survey-submissions">Survey Submissions</Nav.Link>
										</Dropdown.Item>
										<Dropdown.Item>
											<Nav.Link eventKey="/admin/client-metadata">Client MetaData</Nav.Link>
										</Dropdown.Item>
									</NavDropdown>
								</GroupVisibleComponent>
								<NavDropdown title="Data Requests" active={pathname.includes("/data-requests")}>
									<GroupVisibleComponent groups={['Data_Provider', 'FWC_Admin']}>
										<Dropdown.Item>
											<Nav.Link eventKey="/data-requests">All Data Requests</Nav.Link>
										</Dropdown.Item>
									</GroupVisibleComponent>
									<Dropdown.Item>
										<Nav.Link eventKey="/data-requests/user">My Data Requests</Nav.Link>
									</Dropdown.Item>
									<Dropdown.Item>
										<Nav.Link eventKey="/data-requests/create">Request Data</Nav.Link>
									</Dropdown.Item>
								</NavDropdown>
								<GroupVisibleComponent groups={['Data_Provider', 'FWC_Admin']}>
									<Nav.Item>
										<Nav.Link active={pathname.includes("/survey-data")} eventKey="/survey-data">Survey Data</Nav.Link>
									</Nav.Item>
								</GroupVisibleComponent>
							</Nav>
						<div className='user-actions-area'>
							<div className="user-actions">
								<div className="action">
									<div className="data-download-history">
										<i className="fa fa-2x fa-history" title="Downloaded Data History" onClick={() => navigate("/download-history/user")} ></i>
									</div>
								</div>
								<div className="action">
									<i className="fa fa-2x fa-sign-out-alt sign-out-button" title='Sign Out' onClick={signOut}></i>
								</div>
							</div>
							<div className='user-name-display'>{user && user.attributes ? user.attributes.name : ""}</div>
						</div>
					</div>}
				</nav>
			</div>
		</>
	);
}

export default NavBar;

NavBar.propTypes = {
	signOut: PropTypes.func,
	user: PropTypes.object,
	landingPagePath: PropTypes.string
};